import React from 'react'
import './css/Discover.scss'

function Discover() {
    return (
        <div className='discover_wraper'>
            <div className='discover_area'>
                <h1>We love to meet new people</h1>
                <p>Discover Your bigger images</p>
                <button className='discover_button'> OUR SERVICES </button>
            </div>
        </div>
    )
}

export default Discover
