import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";
import "./css/Slide.scss";
import logo1 from "../assets/logos/logo-1.png";
import logo2 from "../assets/logos/logo-2.png";
import logo3 from "../assets/logos/logo-3.png";
import logo4 from "../assets/logos/logo-4.png";
import logo5 from "../assets/logos/logo-5.png";
import logo6 from "../assets/logos/logo-6.png";
import logo7 from "../assets/logos/logo-7.png";
import logo8 from "../assets/logos/logo-8.png";
import logo9 from "../assets/logos/logo-9.png";
import logo10 from "../assets/logos/logo-10.png";
import logo11 from "../assets/logos/logo-11.png";
import logo12 from "../assets/logos/logo-12.png";
import logo13 from "../assets/logos/logo-13.png";
import logo14 from "../assets/logos/logo-14.png";

function Slider() {
  const [bloglist, setBloglist] = useState(5);

  //   useEffect(() => {
  //     if (window.screen.width <= 750) {
  //       setBloglist(1);
  //     } else setBloglist(5);
  //   }, []);

  useEffect(() => {
    if (window.screen.width <= 450) {
      setBloglist(1);
    } else if (window.screen.width <= 750) {
      setBloglist(2);
    } else if (window.screen.width <= 1050) {
      setBloglist(3);
    } else if (window.screen.width <= 1200) {
      setBloglist(4);
    } else setBloglist(5);
  }, []);

  // const Blogarr = [
  //   logo1,
  //   logo2,
  //   logo3,
  //   logo4,
  //   logo5,
  //   logo6,
  //   logo7,
  //   logo8,
  //   logo9,
  //   logo10,
  //   logo11,
  //   logo12,
  //   logo13,
  //   logo14,
  // ];
  const Blogarr = [
    {
      imgsrc: logo1,
      title: 'apcotex'
    },
    {
      imgsrc: logo2,
      title: 'cognis'
    }, {
      imgsrc: logo3,
      title: 'gogreen'
    }, {
      imgsrc: logo4,
      title: 'hexion'
    }, {
      imgsrc: logo5,
      title: 'lyondellbasell'
    }, {
      imgsrc: logo6,
      title: 'megabiz'
    }, {
      imgsrc: logo7,
      title: 'momentive'
    }, {
      imgsrc: logo8,
      title: 'nhat huy group'
    }, {
      imgsrc: logo9,
      title: 'omipa'
    }, {
      imgsrc: logo10,
      title: 'pon pure chemicals group'
    }, {
      imgsrc: logo11,
      title: 'ressichem'
    },
    , {
      imgsrc: logo12,
      title: 'sasol'
    },
    , {
      imgsrc: logo13,
      title: 'synthomer'
    },
    , {
      imgsrc: logo14,
      title: 'aditya birla group'
    },
  ]
  return (
    <div className="Blogslider">
      <div className="slideheading">
        <h6 className="blogh2">Suppliers & Partners</h6>
      </div>
      <div className="blogslide">
        <OwlCarousel
          items={bloglist}
          autoplay="1"
          loop
          autoplayTimeout="2000"
        >
          {Blogarr.map((item, index) => {
            return <img key={index} className="imgdata" src={item.imgsrc} alt={item.title} title={item.title} />;
          })}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default Slider;
