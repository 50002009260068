import React, { useEffect, useState } from 'react'
import ProductDetails from '../components/ProductDetails'
import ProductsBanner from '../components/ProductsBanner'
import { useStateValue } from '../StateProvider';
import Axios from "axios";
import { Helmet } from "react-helmet";

function ProductDetailContainer() {
  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

  const [{ Product }, dispatch] = useStateValue();
  const [Prod, SetProd] = useState('');


  useEffect(() => {
    if (Product == null) {
      let product_id = getLastItem(window.location.pathname);
      Axios.get(`https://hutaib.org/hutaib-admin/productsAPI/GetProduct/${product_id}`).
        then((res) => {
          dispatch({
            type: "SET_PRODUCT",
            Product: res.data.products
          })

        }).catch((err) => {
          console.log(err);
        })
    }
  }, [])


  return (
    <div>

      {/* product title and description will change */}

      <Helmet>
        <meta charSet="utf-8" />
        <title>{Product ? Product.title : ''} </title>
        <meta name="description" content={Product ? Product.product_desc : ''} data-react-helmet="true" />
        <meta name="robots" content="noindex" />

      </Helmet>

      {/* <ProductsBanner /> */}

      <ProductDetails product={Product ? Product : Prod} />

    </div>
  )
}

export default ProductDetailContainer
