import React from 'react'
import './css/Pagenotfound.scss'
function Pagenotfound() {
    return (
        <div className='pagenotfound'>
            <div>
                <h1>404 | Error</h1>
                <h1>Page not found</h1>
            </div>
        </div>
    )
}

export default Pagenotfound
