import React from 'react'
import './css/AboutBanner.scss';
import SearchField from './SearchField';

function AboutBanner() {



  return (
    <div className='AboutBanner' role="img" aria-label="Hutaib About Us" title='Hutaib About Us' >
      <div className='banner_inner'>
        {/* <SearchField  /> */}
      </div>
    </div>
  )
}

export default AboutBanner